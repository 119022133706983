import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Card, Button, Tag, message, Table, Row, Col, Tabs, Tooltip, Modal, Spin } from "antd";
import { useGetListApi } from "../utils/Network";
import { DownloadOutlined, PoweroffOutlined } from '@ant-design/icons';
import { API_CALL } from "../utils/Network";
import { useMainContext } from "../utils/MainContext";

const transactionOptions = { endPoint: "/transactions", listKey: "signal" };

const OrdersPage = () => {
  const [currentPageOpenOrders, setCurrentPageOpenOrders] = useState(1);
  const [pageSizeOpenOrders, setPageSizeOpenOrders] = useState(10);
  const [totalOpenOrders, setTotalOpenOrders] = useState(0);

  const [currentPageAllOrders, setCurrentPageAllOrders] = useState(1);
  const [pageSizeAllOrders, setPageSizeAllOrders] = useState(10);
  const [totalAllOrders, setTotalAllOrders] = useState(0);

  const [downloading, setDownloading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [positionToClose, setPositionToClose] = useState(null);

  const { getSocketData } = useMainContext();
  const socketData = getSocketData();

  const { getList: fetchAllOrders, list: allOrdersList, loading: loadingAllOrders } = useGetListApi({
    ...transactionOptions,
    pagination: { page: currentPageAllOrders, per_page: pageSizeAllOrders },
    onSuccess: (response) => setTotalAllOrders(response.data.total),
    onError: (error) => console.error("Failed to fetch all orders:", error),
  });

  const { getList: fetchOpenOrders, list: openOrdersList, loading: loadingOpenOrders } = useGetListApi({
    ...transactionOptions,
    endPoint: `/transactions?status=open`,
    pagination: { page: currentPageOpenOrders, per_page: pageSizeOpenOrders },
    onSuccess: (response) => setTotalOpenOrders(response.data.total),
    onError: (error) => console.error("Failed to fetch open orders:", error),
  });

  useEffect(() => {
    fetchAllOrders();
  }, [currentPageAllOrders, pageSizeAllOrders]);

  useEffect(() => {
    fetchOpenOrders();
  }, [currentPageOpenOrders, pageSizeOpenOrders]);

  const downloadOrders = useCallback(async (status = "") => {
    setDownloading(true);
    try {
      const endpoint = status ? `/transactions/download?status=${status}` : "/transactions/download";
      const response = await API_CALL.get(endpoint, { responseType: 'blob' });

      const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${status ? status : 'all'}_orders.csv`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      message.error(`Failed to download ${status ? status : 'all'} orders`);
      console.error("Download error:", error);
    } finally {
      setDownloading(false);
    }
  }, []);

  const closePosition = useCallback(async (positionToClose) => {
    try {
      if (!positionToClose || !positionToClose.symbol) return;

      // Get latest price from socketData
      const latestTrade = socketData[positionToClose.symbol]?.slice(-1)[0];
      const assetPrice = latestTrade ? parseFloat(latestTrade.p).toFixed(2) : positionToClose.entry_price;

      if (!assetPrice) {
        message.error("Could not fetch latest price for closing position.");
        return;
      }

      // Determine side and type based on action
      const side = positionToClose.action === "BUY" ? "SELL" : "BUY";
      const type = positionToClose.action === "BUY" ? "exit long" : "exit short";

      // Construct payload
      const payload = {
        symbol: positionToClose.symbol,
        side: side,
        price: assetPrice,
        type: type,
        strategy_name: positionToClose.strategyName,
      };

      const response = await API_CALL.post(`/webhook/tv`, payload);
      if (response?.responses && Array.isArray(response.responses)) {
        const result = response.responses[0]; // Access the first response
    
        if (result?.status === "success") {
          if (result.data) {
            message.success(result.message || "Operation successful.");
            console.log("Response Data:", result.data);
          } else {
            message.success(result.message || "Position processed successfully.");
          }
          fetchOpenOrders(); // Refresh open orders
        } else {
          message.error(result.message || "Operation failed.");
          fetchOpenOrders();
        }
      } else {
        message.error("Unexpected API response format.");
        console.error("Invalid response format:", response.data);
      }
    } catch (error) {
      message.error("Error closing position.");
      console.error("API call error:", error);
    }
  }, [fetchOpenOrders, socketData]);


  const handleConfirmClose = () => {
    if (positionToClose) {
      closePosition(positionToClose);
    }
    setConfirmModalVisible(false);
  };

  const commonCols = useMemo(() => [
    { title: "Symbol", dataIndex: "symbol", key: "symbol" },
    { title: "Strategy", dataIndex: "strategyName", key: "strategyName" },
    {
      title: "Trade Type",
      dataIndex: "trade_type",
      key: "trade_type",
      align: "center",
      render: (tradeType = "") => <Tag color="blue">{tradeType.toUpperCase()}</Tag>,
    },
    {
      title: "Position Type",
      dataIndex: "position_type",
      key: "position_type",
      align: "center",
      render: (positionType = "") => (
        <Tag color={positionType === "Short" ? "red-inverse" : "green-inverse"}>
          {positionType.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Position Status",
      dataIndex: "position_status",
      key: "position_status",
      align: "center",
      render: (status = "") => <Tag color="gold">{status.toUpperCase()}</Tag>,
    },
    {
      title: "Entry Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp = "") => new Date(timestamp).toLocaleString(),
    },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    {
      title: "Entry Price",
      dataIndex: "entry_price",
      key: "entry_price",
      render: (entryPrice) => entryPrice ? `$${entryPrice}` : "-",
    },
  ], []);

  const openOrdersColumns = useMemo(() => [
    {
      title: "S. no.",
      key: "serialNumber",
      render: (text, record, index) => index + 1 + (currentPageOpenOrders - 1) * pageSizeOpenOrders,
    },
    ...commonCols,
    {
      title: "LTP",
      dataIndex: "symbol",
      key: "ltp",
      render: (symbol) => {
        const latestTrade = socketData[symbol]?.[socketData[symbol].length - 1];
        const p = latestTrade ? parseFloat(latestTrade.p) : undefined;
        return p ? `$${p.toFixed(2)}` : "-";
      },
    },
    {
      title: "Current PnL",
      dataIndex: "symbol",
      key: "currentPnl",
      render: (symbol, record) => {
        const latestTrade = socketData[symbol]?.[socketData[symbol].length - 1];
        const p = latestTrade ? parseFloat(latestTrade.p) : undefined;
        if (p !== undefined) {
          const entryPrice = record.entry_price || 0;
          const quantity = record.quantity || 0;
          const currentPnl = record.position_type.toLowerCase() === "long"
            ? (p - entryPrice) * quantity
            : (entryPrice - p) * quantity;
          const formattedValue = `$${currentPnl.toFixed(2)}`;
          const style = { color: currentPnl > 0 ? 'green' : 'red' };
          return <span style={style}>{formattedValue}</span>;
        }
        return "-";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Tooltip placement="bottom" title="Close this position">
          <span onClick={() => {
            setPositionToClose(record);
            setConfirmModalVisible(true)
          }}><PoweroffOutlined /></span>
        </Tooltip>
      ),
    },
  ], [commonCols, currentPageOpenOrders, pageSizeOpenOrders, socketData]);

  const allOrdersColumns = useMemo(() => [
    {
      title: "S. no.",
      key: "serialNumber",
      render: (text, record, index) => index + 1 + (currentPageAllOrders - 1) * pageSizeAllOrders,
    },
    ...commonCols,
    {
      title: "Exit Price",
      dataIndex: "exit_price",
      key: "exit_price",
      render: (exitPrice) => exitPrice ? `$${exitPrice}` : "-",
    },
    {
      title: "Exit Time",
      dataIndex: "closed_at",
      key: "closed_at",
      render: (closeAt = "") => {
        const date = new Date(closeAt);
        return isNaN(date.getTime()) ? "-" : date.toLocaleString();
      },
    },
    {
      title: "PnL",
      key: "profit_or_loss",
      render: (_, record) => {
        const { entry_price, exit_price, position_type, quantity } = record;
        if (!entry_price || !exit_price || !quantity) return "-";
        const profitOrLoss = position_type.toLowerCase() === "long"
          ? (exit_price - entry_price) * quantity
          : (entry_price - exit_price) * quantity;
        const formattedValue = `$${profitOrLoss.toFixed(2)}`;
        const style = { color: profitOrLoss > 0 ? 'green' : 'red' };
        return <span style={style}>{formattedValue}</span>;
      },
    },
    {
      title: "Percent Gain",
      key: "percent_gain",
      render: (_, record) => {
        const { entry_price, exit_price, quantity, position_type } = record;
        if (!entry_price || !exit_price || !quantity || !position_type) return "-";
        const pnl = position_type.toLowerCase() === "long"
          ? (exit_price - entry_price) * quantity
          : (entry_price - exit_price) * quantity;
        const initialInvestment = entry_price * quantity;
        const percentGain = (pnl / initialInvestment) * 100;
        const formattedValue = `${percentGain.toFixed(2)}%`;
        const style = { color: percentGain > 0 ? 'green' : 'red' };
        return <span style={style}>{formattedValue}</span>;
      },
    },
  ], [commonCols, currentPageAllOrders, pageSizeAllOrders]);

  const handleTabChange = (key) => {
    setCurrentPageOpenOrders(1);
    setCurrentPageAllOrders(1);
  };


  return (
    <Card bordered className="orders-page-wrapper">
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <Tabs.TabPane tab="Open Orders" key="1">
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col><h2>Open Orders Management</h2></Col>
            <Col>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => downloadOrders('open')}
                type="primary"
                disabled={downloading}
                style={{ backgroundColor: '#00b96b', borderColor: '#00b96b' }}
              >
                {downloading ? <Spin /> : "Download Open Orders"}
              </Button>
            </Col>
          </Row>
          <Table
            columns={openOrdersColumns}
            dataSource={openOrdersList}
            loading={loadingOpenOrders}
            pagination={{
              current: currentPageOpenOrders,
              pageSize: pageSizeOpenOrders,
              total: totalOpenOrders,
              onChange: (page, pageSize) => {
                setCurrentPageOpenOrders(page);
                setPageSizeOpenOrders(pageSize);
              },
            }}
            rowKey={(record) => record._id}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Closed Orders" key="2">
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col><h2>Closed Orders Management</h2></Col>
            <Col>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => downloadOrders('')}
                type="primary"
                disabled={downloading}
              >
                {downloading ? <Spin /> : "Download Closed Orders"}
              </Button>
            </Col>
          </Row>
          <Table
            columns={allOrdersColumns}
            dataSource={allOrdersList}
            loading={loadingAllOrders}
            pagination={{
              current: currentPageAllOrders,
              pageSize: pageSizeAllOrders,
              total: totalAllOrders,
              onChange: (page, pageSize) => {
                setCurrentPageAllOrders(page);
                setPageSizeAllOrders(pageSize);
              },
            }}
            rowKey={(record) => record._id}
          />
        </Tabs.TabPane>
      </Tabs>
      <Modal
        title="Confirm Action"
        visible={confirmModalVisible}
        onOk={handleConfirmClose}
        onCancel={() => setConfirmModalVisible(false)}
      >
        <p>Are you sure you want to close this position?</p>
      </Modal>
    </Card>
  );
};

export default React.memo(OrdersPage);
